body {
    margin: 0;
    font-family: 'Syne Mono', BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight:400;
    color: #000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: white;
    overflow:;
  }
  body.overflowhidden {
    overflow:hidden;
  }
  @import url('https://fonts.googleapis.com/css2?family=Secular+One&family=Syne+Mono&display=swap');
  
  .App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  a {
    color: blue;
    text-decoration: none;;
  }
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .wrap {
    overflow: ;
    width:100%;
  }
  .wrap .inner {
    padding: 30px;
    padding-top:0px;
  }
  .wrap .item {
    /* box-shadow: 1px 1px 1px #ccc; */
    /* width: 100%; */
    /* border: #000 solid 1px; */
    width: 30%;
    float: left;
    /*box-shadow: 0px 0px 20px #0000002d;*/
    position: ;
    /* border-radius: 10%; */
    /* background: linear-gradient(0deg, #ccc, #eee); */
    /* border: 1px #000 solid; */
    /* margin: 7px; */
    border: 5px #ccc ridge;
    line-height:0;
    margin-left: 0%;
    margin-top: 0%;
    cursor: move;
  }
  .wrap .item.my {
    cursor: pointer;
    width: 33%;
    border:0;
  }
  .wrap .item img {
    width: 100%;
    height: auto;
    border:0;
    pointer-events: none;
  }
  .wrap .item canvas {
    width: 100%;
    height: auto;
    pointer-events: none;
  }
  .wrap .item.single_view {
    width: 90%;
    float: none;
    overflow: hidden;
    margin: 0 auto;
    margin-top: 5%;
  }
  .wrap .item.single_view iframe {
    width: 35vw;
    height: 35vw;
    height: 100%;
    float: left;
    overflow:hidden;
  }
  .wrap .item.single_view .info {
    width: 40%;
    float: right;
    font-size: 15px;
    border: 5px #ccc solid;
    padding: 20px;
    
  }
  /**SCREEN**/
  .wrap .item.single_screen {
    width: 100%;
    height: 100%;
    position: absolute;
    border:0;
    border-style:none;
    margin:0;
    padding:0;
    left: 0;
    top:0;
  }
  .wrap .item.single_screen iframe {
    width: 100vw;
    height: 100vw;
    float: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border:0;
  }
  .wrap .item.single_screen .info {
    position: absolute;
      border: 5px #ccc ridge;
      bottom: 100px;
      right: 100px;
      font-size: 20px;
      padding: 20px;
      background: #fff;
      width:auto !important;
  }
  .wrap.info {
    border-top: 1px #000 solid;
    padding-top: 10px;
  }
  .wrap.info .infobox {
    font-size: 23px;
    line-height: 150%;
  }
  .italic {
    font-style:italic;
    
  }
  .wrap.info .infobox .strong {
    font-weight: bold;
  }
  .marquee {
    color: #fff;
    background:blue;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 20px;
    display: ;
  }
  .contact_info {}
  .bar {
    width: 100%;
    text-align: left;
    position:;
  
    position: ;
    height:;
  }
  .bar .item {
    display:inline-block;
    font-size: 20px;
    font-weight: 600;
    padding: 0;
    color: black;
    mix-blend-mode: multiply;
    padding: 20px;

  }
  .bar .item a.active{
    text-decoration: underline;
  }
  .bar .item a {
    text-decoration: none;
    color: #000;
    font-weight:normal;
  }
  .bar .profileCanvas canvas {
    width: 100px;
    height: 100px;
    position: absolute;
    right: 200px;
    top: -15px;
    pointer-events: none;
  }
  .contract_info {
    position: fixed;
    right: 20px;
    bottom: 20px;
    width: 200px;
    padding: 10px;
    border: 5px #ccc ridge;
    background: #fff;
    z-index:9999;
    cursor: move;
  }
  .contract_info .item {
    display: block;
    padding:0;
    font-weight: 300;
    font-size: 12px;
  }
  .hoverbox {
    position: fixed;
    background: #fff;
    font-size: 15px;
    border: 1px #000 solid;
    padding: 15px;
    overflow:hidden;
    min-width: 200px;
    z-index: 9999999999;
  }
  button {
    padding: 9px 16px;
    border-color: #000;
    color: #000;
    background-color: none;
    border-radius: 8px;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    font-weight: bold;
    cursor:pointer;
  }
  button#walletButton {
    line-height: 100%;
    padding: 10px;
    max-height:none;
    position: absolute;
    right: 16px;
    top: 16px;
    font-size: 13px;
    background:none;
    border-width: 1px;
    font-family:'Syne Mono';
    text-align:left;
  
  }
  button#mintButton {
    font-size: 20px;
    background:linear-gradient(white, blue);
    padding: 20px;
    border-radius: 20px;
    color:#fff;
    text-shadow: 0px 1px 0px #000;
    border:1px #000 solid;
  }
  button#mintButton:active {
    text-shadow: 0px -1px 0px #000;
  }
  .wrap .minter, .wrap.redeem .redeem {
    width: 50%;
    margin: 0 auto;
    text-align: center;
    position: absolute;
    border-radius: 20px;
    left: 50%;
    top: 50%;
    transform:translate3d(-50%, -50%, 0);
    padding: 20px;
  }
  .wrap.redeem .redeem {
    line-height: 200%;
  }
  .wrap .info {
    font-size: 15px;
    margin: 0 auto;
    margin-top: 0px;
    line-height: 150%;
    border: 5px #ccc ridge;
    padding: 30px;
    width: 400px;
    background: white;
    z-index: 99999999;
    cursor:move;
  }
  @media not all and (min-resolution:.001dpcm) { @media {
    .wrap .info, .contract_info {border: 5px rgb(151, 151, 151) ridge;}
    .wrap .item {border: 5px rgb(151, 151, 151) ridge;}
  }}
  input {
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: groove;
    font-size: 16px;
    width: 100%;
    border-color: rgba(4, 4, 5, 0.1);
    line-height: 32px;
  }
  
  input:focus {
    outline: none;
    border-color: rgba(4, 4, 5, 0.4);
  }
  
  #title {
    padding-top: 32px;
  }
  
  #status {
    color:black;
    font-size: 20px;
    font-weight: 400;
  }
  #status a {
    color:black
  }
  
  .uploadFile {
    border: 4px dashed rgba(4, 4, 5, 0.1);
    border-radius: 16px;
    text-align: center;
    padding: 32px 60px;
  }
  .wrap.redeem .helper {
    width: ;
    padding: 30px;
    font-size: 25px;
    margin: 0 auto;
    border-bottom: 1px #000 solid;
  }
  .react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
    display: none;
  }
  .toggleView {
    font-size: 12px;
    float: right;
    text-align: right;
    width: 100%;
    vertical-align: middle;
    display: none;
  }
  .toggleView .switch_wrap {
    display: inline-block;
    vertical-align: middle;

  }
  .toggleView .label {
    font-size: 12px;
    display: inline-block;
    vertical-align: middle;
    padding-right:10px;
  }
  .react-switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 50px;
    height: 25px;
    border-radius: 100px;
    position: relative;
    transition: background-color .1s;
    border: 1px #000 solid;
  }
  
  .react-switch-label .react-switch-button {
    content: '';
    position: absolute;
    top: 1.5px;
    left: 1.5px;
    width: 20px;
    height: 20px;
    border-radius: 22.5px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
    border: 1px #000 solid;
  }
  
  .react-switch-checkbox:checked + .react-switch-label .react-switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
  .react-switch-checkbox:checked + .react-switch-label {
  }
  .react-switch-label:active .react-switch-button {
    width: 60px;
  }
  
  /**PHONE**/
  @media (max-width: 900px) {
    .bar .item {
      padding-left: 10px;
      padding-right: 10px;
      font-size: 18px;
    }
    .bar .item:first-of-type {
      padding-left: 20px;
    }
    .bar #walletButton {
      margin-top: -2px;
    }
    .wrap .item {
      width: 50%;
      border:0;
    }
    .wrap.redeem .redeem {
      position: static;
      transform: none;
      margin-top: 30px;
    }
    .profileCanvas{display:none;}
    .wrap .info {
      width:calc(100vw - 170px) !important;
      position: absolute !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
      margin-top: 30px;
      padding:25px;
    }
    .item.my {
      width: 100% !important;
    }
    .wrap.mytokens .hoverbox {
      display:block !important;
    }
    .infobox {
      font-size: 18px !important;
    }
    .hoverbox {display: none;}
    .wrap.redeem .helper {
      font-size: 15px;
      line-height: 150%;
      border: 5px rgb(151, 151, 151) ridge;
      width: 70%;
      margin:0 auto;
      margin-top: 30px;
      padding: 25px;
    }
    .wrap.redeem .code {
      font-size: 15px !important;
      line-height: 150%;
      margin-top: 40px;
    }
    #mintButton {
      font-size: 14px !important;
    }
    .contract_info {
      display: none;
    }
  }
  @media (max-width: 500px) {
  }
  @media (min-width: 1500px) {
    .wrap .item {
      width: 25%;
    }
  }